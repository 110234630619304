<template>
  <p>{{ formatedTime }}</p>
</template>

<script>
export default {
  data() {
    return {
      secondsLeft: 60 * 30,
      timeoutId: null,
    };
  },
  computed: {
    formatedTime() {
      const minutes = Math.floor(this.secondsLeft / 60);
      const remainingSeconds = this.secondsLeft % 60;
      return `${minutes
        .toString()
        .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
    },
  },
  methods: {
    run() {
      this.timeoutId = setTimeout(() => {
        if (this.secondsLeft === 0) {
          return;
        }
        this.secondsLeft--;
        this.run();
      }, 1000);
    },
  },
  mounted() {
    this.run();
  },
  beforeUnmount() {
    if (this.timeoutId) {
      clearInterval(this.timeoutId);
    }
  },
};
</script>

<style scoped>
.timer-mobile .timer {
  margin-top: 16px;
}

.timer {
  background-color: #1f2127cc;
  padding: 8px 12px;
  border-radius: 12px;
  text-align: center;
  width: 100%;
  min-width: 125px;
}

.timer p {
  color: #ffffff99;
  font-size: 12px;
}

.timer div {
  color: #ffffff;
  font-size: 16px;
}
</style>
